import { Controller } from "stimulus";
import Inputmask from "inputmask";

export default class extends Controller {
  static targets = ["cardType", "cardNumber", "hint"]

  connect() {
    this.updateCardNumberMask();
  }

  updateCardNumberMask() {
    const cardTypeName = this.cardTypeTarget.value;
    const maskPattern = this.cardTypeTarget.selectedOptions[0].dataset.maskPattern;

    // Show or hide hint message based on card type
    if (cardTypeName && this.cardTypeTarget.selectedOptions[0].text === 'Caritas Vaud') {
      this.hintTarget.style.display = 'block';
    } else {
      this.hintTarget.style.display = 'none';
    }

    if (this.cardNumberTarget.inputmask) {
      this.cardNumberTarget.inputmask.remove();
    }

    if (maskPattern) {
      Inputmask({ regex: maskPattern, placeholder: "_" }).mask(this.cardNumberTarget);
    }
  }
}
