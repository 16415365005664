import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["password", "toggle"]

  connect() {
    this.toggleTarget.classList.add("fa")
    this.hide();
  }

  toggle(e) {
    if(this.passwordTarget.type == "password") {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.toggleTarget.classList.remove("fa-eye-slash")
    this.toggleTarget.classList.add("fa-eye")

    this.passwordTarget.type = "text"
  }

  hide() {
    this.toggleTarget.classList.remove("fa-eye")
    this.toggleTarget.classList.add("fa-eye-slash")

    this.passwordTarget.type = "password"
  }
}
