// Example usage:
// <div data-controller="tooltip" data-tippy-content="Hello world"></div>
import { Controller } from "stimulus"
import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'tippy.js/themes/light-border.css';

export default class extends Controller {
  static values = {
    content: String,
    theme: String,
  }

  connect() {
    let options = {}
    if (this.hasContentValue) {
      options['content'] = this.contentValue
    }
    if (this.hasThemeValue) {
      options['theme'] = this.themeValue
    }

    this.tippy = tippy(this.element, {allowHTML: true, ...options});
  }

  disconnect() {
    if (this.tippy) {
      this.tippy.destroy();
    }
  }
}