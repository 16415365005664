// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Notification from "stimulus-notification"

const application = Application.start()

export function loadControllers() {
  const context = require.context("controllers", true, /(?<!maps|stripe)_controller\.js$/)

  application.load(definitionsFromContext(context))
}

export function loadUnsavedChangesControllers() {
  const context = require.context("controllers", true, /unsaved_changes_controller\.js$/)

  application.load(definitionsFromContext(context))
}

export function loadDistributeMoneyStepOneControllers() {
  const context = require.context("controllers", true, /distribute_money_step_one_controller\.js$/)

  application.load(definitionsFromContext(context))
}

export function loadMapsControllers() {
  const context = require.context("controllers", true, /maps_controller\.js$/)

  application.load(definitionsFromContext(context))
}

export function loadTooltipControllers() {
  const context = require.context("controllers", true, /tooltip_controller\.js$/)

  application.load(definitionsFromContext(context))
}

import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"

export function loadTailwindcssStimulusComponents() {
  application.register('alert', Alert)
  application.register('autosave', Autosave)
  application.register('dropdown', Dropdown)
  application.register('modal', Modal)
  application.register('tabs', Tabs)
  application.register('popover', Popover)
  application.register('toggle', Toggle)
  application.register('slideover', Slideover)
}

export function loadNotification() {
  application.register("notification", Notification)
}

export function loadDynamicInputmask() {
  const context = require.context("controllers", true, /dynamic_inputmask_controller\.js$/)

  application.load(definitionsFromContext(context))
}

import Chart from "stimulus-chartjs"
import CheckboxSelectAll from "stimulus-checkbox-select-all"

export function loadStimulusComponents() {
  application.register("chart", Chart)
  application.register('checkbox-select-all', CheckboxSelectAll)
}
