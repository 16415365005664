// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("packs/select2setup")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//

import 'bootstrap'
import Inputmask from "inputmask";

import "@fortawesome/fontawesome-free/css/all.css";

import { loadControllers, loadMapsControllers } from "controllers"

loadControllers()

window['$'] = $;
window.Turbolinks.scroll = {};

const turbolinksScroll = function () {
  if (Turbolinks.scroll['top']) {
    document.scrollingElement.scrollTo(0, Turbolinks.scroll['top']);
    window.console.log("Scrolled to" + Turbolinks.scroll['top']);

    Turbolinks.scroll = {};
  }

  const elements = document.querySelectorAll("[data-turbolinks-scroll]");

  elements.forEach(function(element){
    element.addEventListener("click", ()=> {
      document.addEventListener("turbolinks:before-render", ()=> {
        Turbolinks.scroll['top'] = document.scrollingElement.scrollTop;
      });
    });

  });
}

document.addEventListener("turbolinks:load", function() {
  Inputmask().mask(document.querySelectorAll("input"));

  turbolinksScroll();
});

document.addEventListener("turbolinks:render", function() {
  turbolinksScroll();
});

const api_key = process.env.MAPS_API_KEY;
const { Loader, LoaderOptions } = require('google-maps');
const options = { libraries: ["places"] };
const loader = new Loader(api_key, options);

loader.load().then((google) => {
  console.log("Loaded Google.js")

  window['google'] = google;

  loadMapsControllers()
});

