import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["open", "close", "checkbox"]

  connect() {
    this.saveValues();
    this.apply(this.checkboxTarget);
  }

  toggle(e) {
    this.apply(e.target);
  }

  apply(input) {
    if(input.checked) {
      this.disable();
    } else {
      this.enable();
    }
  }

  enable() {
    this.restoreValues();

    this.openTarget.readOnly = false
    this.closeTarget.readOnly = false
  }

  disable() {
    this.saveValues();

    this.openTarget.value = null
    this.closeTarget.value = null

    this.openTarget.readOnly = true
    this.closeTarget.readOnly = true
  }

  saveValues() {
    this._values = [this.openTarget.value, this.closeTarget.value];
  }

  restoreValues() {
    this.openTarget.value = this._values[0];
    this.closeTarget.value = this._values[1];
  }
}
