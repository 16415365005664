import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox" ]

  alert_if_necessary(event) {
    if (this.checkboxTargets.filter(checkbox => checkbox.checked).length === 0) {
      alert("Vous devez sélectionner au moins un commerce");
      event.preventDefault();
    }
  }
}
