import { Controller } from "stimulus"

require("pwstrength-bootstrap/dist/pwstrength-bootstrap.min.js")

export default class extends Controller {
  static targets = ["password", "progress"]

  connect() {
    $(this.passwordTarget).pwstrength(
      {
        common: {
          minChar: 8,
        },
        ui: {
          showVerdictsInsideProgressBar: true,
          container: this.element,
          viewports: {
            progress: this.progressTarget,
            // verdict: undefined,
            // errors: undefined,
            // score: undefined
          }
        },
      }
    );
  }
}
