import { Controller } from "stimulus"
import debounce from "debounce"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    const options = {
      types: ['(regions)'],
      componentRestrictions: {country: 'ch'}
    }

    this._autocomplete =
      new google.maps.places.Autocomplete(this.inputTarget, options);
  }
}
