import { Controller } from "stimulus"
import debounce from "debounce"
import styles from "../map-styles"

const blackDot = require("../images/black-dot.svg");
const defaultLocality = { lat: 46.2044, lng: 6.1432 };

export default class extends Controller {
  static targets = ["map", "error", "latitude", "longitude", "locality"]

  connect() {
    const mapOptions = {
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      center: defaultLocality,
      zoom: 13,
      styles: styles
    }

    this._map = new google.maps.Map(this.mapTarget, mapOptions);
    this._geocoder = new google.maps.Geocoder();

    this._place = this.getPlace();

    this.centerMap(this._place || defaultLocality);

    if(this._place) this.placeMarker(this._place);
  }

  geoInput(e) { this.debouncedGeocode(e.target.value); }

  geocode(address) {
    this._geocoder.geocode({'address': address}, (results, status) => {
      if (status == 'OK') {
        const location = results[0].geometry.location;

        const locality = results[0].address_components.find(x => x.types.includes('locality')).long_name

        this.setLocality(locality)
        this.setLatLng({lat: location.lat(), lng: location.lng()});
        this.centerMap(location);
        this.placeMarker(location);
      } else {
        this.setLatLng({lat: 0, lng: 0});
        this.centerMap({lat: 0, lng: 0});
      }
    });
  }

  debouncedGeocode = debounce((a) => this.geocode(a), 1400)

  placeMarker(latLng) {
    if(this._marker) this._marker.setMap(null);

    this._marker = new google.maps.Marker({
      position: latLng,
      map: this._map,
      icon: { url: blackDot }
    });
  }

  setLocality(locality) {
    this.localityTarget.value = locality;
  }

  setLatLng(latLng) {
    this.latitudeTarget.value = latLng.lat;
    this.longitudeTarget.value = latLng.lng;
  }

  getPlace() {
    if(this.hasLatitudeTarget && this.hasLongitudeTarget) {
      const lat = parseFloat(this.latitudeTarget.value);
      const lng = parseFloat(this.longitudeTarget.value);

      if (lat && lng) {
        return { lat: lat, lng: lng };
      } else {
        return null;
      }
    } else if(this.data.has('latitude') && this.data.has('longitude')) {
      const lat = parseFloat(this.data.get('latitude'));
      const lng = parseFloat(this.data.get('longitude'));

      if (lat && lng) {
        return { lat: lat, lng: lng };
      } else {
        return null;
      }

    } else {
      return null;
    };
  }

  centerMap(location) {
    this._map.setCenter(location);
  }

  getGeolocation() {
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latlng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }

          this.centerMap(latlng);
        },(e) => {
          if(this.hasErrorTarget) {
            this.errorTarget.innerText = e.message
          }
        }
      );
    }
  }
}
