import { Controller } from "stimulus";
import SnazzyInfoWindow from "snazzy-info-window";
import debounce from "debounce";
import styles from "../map-styles";

const blackDot = require("../images/black-dot.svg");

const defaultPlace = { lat: 46.8181877, lng: 8.2275124 }; // Switzerland

export default class extends Controller {
  static targets = ["map", "marker"]

  connect() {
    const mapOptions = {
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      zoom: 9,
      center: defaultPlace,
      styles: styles
    }

    this._map = new google.maps.Map(this.mapTarget, mapOptions);
    this._bounds = new google.maps.LatLngBounds();

    this._positions = [];
    this._markers = [];

    this.markerTargets.forEach(template => {
      const marker = this.createMarkerFromTemplate(template);
      this._markers.push(marker);
    });

    this._positions.forEach(p => this._bounds.extend(p));

    if (this._bounds.isEmpty()) {
      this._map.setCenter(defaultPlace);
    } else {
      this._map.setCenter(this._bounds.getCenter());
      this._map.fitBounds(this._bounds);
    }

    google.maps.event.addListenerOnce(this._map, "idle", () => {
      this._map.setZoom(this._map.getZoom() - 1);

      if (this._map.getZoom() > 14) this._map.setZoom(14);
    });

    google.maps.event.addListener(this._map, "idle", debounce(() => {
      this.updateMarkers();
    }, 200));
  }

  createMarkerFromTemplate(template) {
    const position = {
      lat: parseFloat(template.dataset.lat),
      lng: parseFloat(template.dataset.lng)
    };
    this._positions.push(position);

    const marker = new google.maps.Marker({
      position: position,
      map: this._map,
      icon: { url: blackDot }
    });

    const renderedDiv = document.createElement('div');
    renderedDiv.innerHTML = template.innerHTML;

    new SnazzyInfoWindow({
      marker: marker,
      content: renderedDiv,
      padding: "0px",
      maxWidth: 400,
      borderRadius: "8px",
    });

    return marker;
  }

  createMarkerFromData(data) {
    const position = {
      lat: parseFloat(data.latitude),
      lng: parseFloat(data.longitude)
    };

    const marker = new google.maps.Marker({
      position: position,
      map: this._map,
      icon: { url: blackDot }
    });

    const renderedDiv = document.createElement('div');
    renderedDiv.innerHTML = `
      <div>
        <p class="bg-primary text-white p-4">
          <span class="h5">${data.name}</span><br>
          <span>${data.address}</span>
        </p>
        <div class="row no-gutters p-4">
          <div class="col-8 pr-4">
            <p class="mb-3 clamp-4">${data.description}</p>
          </div>
          <div class="col-4">
            <!-- Business image tag here if available -->
          </div>
        </div>
        <div class="row no-gutters p-4 flex-column-reverse flex-md-row">
          <div class="col-md-4 text-center text-md-left">
            <a href="/businesses/${data.id}" class="btn btn-success shadow-none">Visiter</a>
          </div>
          <div class="col-md-8 text-md-right">
            ${data.website ? `<p class="mb-3 text-truncate"><i class="fas fa-globe mr-2"></i>&nbsp;${data.website}</p>` : ''}
            ${data.phone_number ? `<p class="mb-3"><i class="fas fa-phone-alt mr-2"></i>&nbsp;${data.phone_number}</p>` : ''}
          </div>
        </div>
      </div>
    `;

    new SnazzyInfoWindow({
      marker: marker,
      content: renderedDiv,
      padding: "0px",
      maxWidth: 400,
      borderRadius: "8px",
    });

    return marker;
  }

  updateMarkers() {
    const bounds = this._map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    const params = new URLSearchParams({
      sw_lat: sw.lat(),
      sw_lng: sw.lng(),
      ne_lat: ne.lat(),
      ne_lng: ne.lng()
    });

    fetch(`/businesses/within_bounds?${params.toString()}`)
      .then(response => response.json())
      .then(data => {
        const newMarkers = [];

        data.forEach(business => {
          const marker = this.createMarkerFromData(business);
          newMarkers.push(marker);
        });

        // Only clear and set new markers if the data has changed
        if (this.markersChanged(newMarkers)) {
          this._markers.forEach(marker => marker.setMap(null));
          this._markers = newMarkers;
        }
      })
      .catch(error => console.error('Error fetching markers:', error));
  }

  markersChanged(newMarkers) {
    if (newMarkers.length !== this._markers.length) return true;

    for (let i = 0; i < newMarkers.length; i++) {
      if (!newMarkers[i].getPosition().equals(this._markers[i].getPosition())) return true;
    }

    return false;
  }
}
